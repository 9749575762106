import React from 'react';
import {Link, useLocation} from 'react-router-dom'
import {useFetchedData} from '../../components/useFetchedData';
import {setLanguage, withLanguage} from '../../helpers/language';

import logo from '../../assets/images/logo.svg';
import spinner from '../../assets/spinners/spinner.svg';
import css from './Header.module.scss';

const Header = () => {
  const location = useLocation();
  const [loading, data] = useFetchedData('singletons/get/menu');
  return (
    <>
      {loading ? (
        <img src={spinner} alt={"loading"}/>
      ) : (
        <>
          <div className={css.Logo}>
            <Link to="/"><img src={logo} alt="logo"/></Link>
          </div>
          {location.pathname === '/' && (
            <div className={css.Menu}>
              <ul>
                <li><Link to="/about">
                  {
                    withLanguage(data, 'fields')
                      .filter(field => field.value.destination === 'about')[0].value.name
                  }
                </Link></li>
                <li><Link to="/publications">
                  {
                    withLanguage(data, 'fields')
                      .filter(field => field.value.destination === 'publications')[0].value.name
                  }
                </Link></li>
                <li><a href="https://www.instagram.com/bosko.projekty/">
                  {
                    withLanguage(data, 'fields')
                      .filter(field => field.value.destination === 'instagram')[0].value.name
                  }
                </a></li>
              </ul>
            </div>
          )}
          <div className={css.LanguageSwitcher}>
            <button onClick={() => {setLanguage('pl')}}>
              pl
            </button>/
            <button onClick={() => {setLanguage('en')}}>
              en
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default Header;
