export const setLanguage = language => {
  if (typeof language === 'undefined' && !window.localStorage.getItem('language')) {
    const userLang = window.navigator.userLanguage === 'pl' || window.navigator.language === 'pl' ? 'pl' : 'en';
    window.localStorage.setItem('language', userLang);
  } else if (!!language){
    window.localStorage.setItem('language', language);
    window.location.reload();
  }
};

export const withLanguage = (fieldObj, field) => {
  if (fieldObj[`${field}_${window.localStorage.getItem('language')}`]) {
    return fieldObj[`${field}_${window.localStorage.getItem('language')}`]
  } else {
    return fieldObj[field]
  }
};
