import {useEffect, useState} from 'react';

export const useFetchedData = (endpoint) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const apiUrl = `${window.location.protocol}//bo-sko.pl/cms/api/`;
  const token = '480b7b5ec26e059e59f7e80bea2311';

  useEffect(() => {
    (async () => {
      setLoading(true);

      if (window.sessionStorage.getItem(endpoint)) {
        setData(JSON.parse(window.sessionStorage.getItem(endpoint)));
        setLoading(false);
      } else {
        fetch(`${apiUrl}${endpoint}?token=${token}&sort[_created]=-1`)
          .then(result => result.json())
          .then(result => {
            setData(result);
            setLoading(false);
            window.sessionStorage.setItem(endpoint, JSON.stringify(result))
          })
      }
    })()
  }, []);

  return [loading, data]
};
