import React, {lazy, Suspense} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'

import loader from './assets/spinners/spinner.svg';
import Header from './containers/Header/Header';
import {setLanguage} from './helpers/language';
import './App.css'

const About = lazy(() => import('./containers/About/About'));
const Home = lazy(() => import('./containers/Home/Home'));
const Publications = lazy(() => import('./containers/Publications/Publications'));
const SingleProject = lazy(() => import('./containers/SingleProject/SingleProject'));
const SinglePublication = lazy(() => import('./containers/Publications/SinglePublication'));

const App = () => {
  setLanguage();

  return (
    <Router>
    {/*// <Router>*/}
      <div className="contentWrapper">
        <Suspense fallback={<img alt="Loading" src={loader} />}>
          <Header/>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/publications" component={Publications} />
            <Route path="/project/:project" component={SingleProject} />
            <Route path="/publication/:publication" component={SinglePublication} />
          </Switch>
        </Suspense>
      </div>
    </Router>
  );
};

export default App;
